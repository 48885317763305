import { Provider } from "react-redux";
import store from "./Redux/Store/Store";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { Suspense, useEffect, useLayoutEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/Loader";
import LoadingModal from "./components/LoadingModal";
import EnterNumber from "./pages/EnterNumber";
import EnterOtp from "./pages/Otp";
import NameDetails from "./pages/NameDetails";
import OtherDetails from "./pages/OtherDetails";


function App() {
  return (
    <Router>
      <Suspense fallback={<Loader />} />
      <Provider store={store}>
        <LoadingModal />
        <ToastContainer />
        <Routes>
          <Route exact path="/" element={<EnterNumber />} />
          <Route exact path="/phone-otp" element={<EnterOtp />} />
          <Route exact path="/name-details" element={<NameDetails />} />
          <Route exact path="/other-details" element={<OtherDetails />} />        
        </Routes>
      </Provider>
    </Router>
  );
}

export default App;
